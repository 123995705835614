import { Suspense } from 'react';
import { Navigate, NavLink, Outlet, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { ErrorBoundary } from 'react-error-boundary';
import 'react-toastify/dist/ReactToastify.css';

import RequireAuth from 'components/RequiredAuth';
import ErrorPage from 'components/ErrorPage';
import Loading from 'components/Loading';
import { RootState, useAppDispatch, userActions } from 'store';
import { colors, flexbox } from 'styles/common';
import CommonStyle from 'styles/CommonStyles';

import Login from './Login';
import Register from './Register';
import Embed from './Embed';
import EmbedRegister from './Embed/EmbedRegister';
import EmbedModify from './Embed/EmbedModify';
import Widget from './Widget';
import WidgetRegister from './Widget/WidgetRegister';
import WidgetModify from './Widget/WidgetModify';
import IFrameEmbed from './IFrameEmbed';
import IFrameWidget from './IFrameWidget';
import Logout from './Logout';

const Layout = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { user } = useSelector((state: RootState) => state.user);
  const { embedPermission, widgetPermission, logo } = user || {};

  return (
    <Suspense fallback={<Loading />}>
      <ErrorBoundary fallbackRender={({ error, resetErrorBoundary }) => ErrorPage({ error, resetErrorBoundary })}>
        <Wrapper>
          <header>
            <Nav>
              <div>
                <NavLink to={embedPermission ? '/embed/config ' : '/widget/config'}>
                  <img src={`${process.env.REACT_APP_API_BASE_URL}${logo}`} alt='logo' />
                </NavLink>
                {embedPermission && (
                  <NavLink replace to='/embed/config'>
                    임베드 관리
                  </NavLink>
                )}
                {widgetPermission && (
                  <NavLink replace to='/widget/config'>
                    위젯 관리
                  </NavLink>
                )}
              </div>
              <button
                type='button'
                onClick={() => {
                  dispatch(userActions.logout());
                  navigate(`/`, { replace: true });
                }}
              >
                [{user.name}] 로그아웃
              </button>
            </Nav>
          </header>

          <Container>
            <Outlet />
          </Container>
        </Wrapper>
      </ErrorBoundary>
    </Suspense>
  );
};

const App = () => {
  const location = useLocation();

  const { user } = useSelector((state: RootState) => state.user);
  const { embedPermission } = user || {};

  return (
    <>
      <CommonStyle />
      <ToastContainer
        position='top-right'
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme='light'
      />
      <Routes>
        <Route element={<RequireAuth />}>
          <Route element={<Layout />}>
            <Route
              path='/'
              element={
                embedPermission ? (
                  <Navigate state={{ from: location }} to='/embed/config' />
                ) : (
                  <Navigate state={{ from: location }} to='/widget/config' />
                )
              }
            />
            <Route path='/register' element={<Register />} />

            <Route path='/embed/config' element={<Embed />} />
            <Route path='/embed/config/reg/:uuid?' element={<EmbedRegister />} />
            <Route path='/embed/config/mod/:uuid' element={<EmbedModify />} />
            <Route path='/widget/config' element={<Widget />} />
            <Route path='/widget/config/reg/:uuid?' element={<WidgetRegister />} />
            <Route path='/widget/config/mod/:uuid' element={<WidgetModify />} />
          </Route>
        </Route>
        <Route
          path='/login'
          element={
            <Suspense fallback={<Loading />}>
              <Login />
            </Suspense>
          }
        />
        <Route
          path='/embed/:uuid'
          element={
            <Suspense fallback={<Loading />}>
              <ErrorBoundary fallbackRender={({ error, resetErrorBoundary }) => ErrorPage({ error, resetErrorBoundary })}>
                <IFrameEmbed />
              </ErrorBoundary>
            </Suspense>
          }
        />
        <Route path='/widget/:uuid' element={<IFrameWidget />} />
        <Route path='/logout' element={<Logout />} />
        <Route path='*' element={<Navigate to='/' replace />} />
      </Routes>
    </>
  );
};

const Container = styled.div`
  position: relative;
  max-width: 1110px;
  width: 100%;
  min-height: 900px;
  margin: auto;
  text-align: center;
`;

const Nav = styled.nav`
  ${flexbox({ jc: 'space-between', ai: 'center' })};
  margin: 0 auto;
  max-width: 1100px;
  height: 84px;

  svg,
  img {
    width: 150px;
    //height: 87px;
  }

  div {
    ${flexbox({ ai: 'center', gap: 50 })};
  }

  a,
  button {
    color: ${colors.GRAY5};
    font-size: 20px;
  }

  a:visited {
    color: ${colors.GRAY5};
  }
`;
export const Wrapper = styled.div`
  height: 100vh;

  header {
    border-bottom: 1px solid ${colors.GRAYC};
  }
`;

export default App;
