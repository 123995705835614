import { useParams } from 'react-router-dom';

import { useEmbedGet } from 'hooks/useEmbed';
import CommonForm from 'components/CommonForm';
import WrongPage from 'components/WrongPage';

const EmbedModify = () => {
  const { uuid } = useParams();
  const { data } = useEmbedGet({ uuid });

  if (!data) return <WrongPage />;
  return <CommonForm pageType='embed' pageSubmit='수정' data={data.config} uuid={uuid} />;
};

export default EmbedModify;
