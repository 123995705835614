import { useParams } from 'react-router-dom';

import { useWidgetGet } from 'hooks/useWidget';
import CommonForm from 'components/CommonForm';
import WrongPage from 'components/WrongPage';

const WidgetModify = () => {
  const { uuid } = useParams();
  const { data } = useWidgetGet({ uuid });

  if (!data) return <WrongPage />;
  return <CommonForm pageType='widget' pageSubmit='수정' data={data.config} uuid={uuid} />;
};

export default WidgetModify;
