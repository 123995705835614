import styled from 'styled-components';
import { userActions } from '../../store';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

const Logout = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(userActions.logout());
    navigate('/');
  }, [dispatch, navigate]);

  return <Wrapper />;
};

const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  position: relative;
`;

export default Logout;
