import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../store';

const RequireAuth = () => {
  const { token, user } = useSelector((state: RootState) => state.user);
  const location = useLocation();

  return token && user ? <Outlet /> : <Navigate to='/login' state={{ from: location }} replace />;
};

export default RequireAuth;
