import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { instance } from 'hooks/useAxios';
import { IUserInfo, IUserInfoRes } from 'hooks/useUser';

interface IStore {
  token: string | null;
  user: IUserInfo;
}

const noneState: IStore = {
  token: null,
  user: null,
};

const initialState: IStore = {
  token: window.sessionStorage.getItem('tk') || null,
  user: null,
};

export const user = createSlice({
  name: 'user',
  initialState,
  reducers: {
    login: (state, action: PayloadAction<{ token: string }>) => {
      const { token } = action.payload;
      state.token = token;
      window.sessionStorage.setItem('tk', token);
      instance.defaults.headers.common.Authorization = `Bearer ${token}`;
    },
    setUser: (state, action: PayloadAction<IUserInfoRes>) => {
      const { embedPermission, widgetPermission, logo, ...res } = action.payload;
      state.user = {
        ...res,
        embedPermission: Boolean(embedPermission),
        widgetPermission: Boolean(widgetPermission),
        logo,
      };
    },
    logout: () => {
      window.sessionStorage.removeItem('tk');
      delete instance.defaults.headers.common.Authorization;
      return noneState;
    },
  },
});
export const userActions = { ...user.actions };

export default user.reducer;
