import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { FetchNextPageOptions, UseInfiniteQueryResult } from 'react-query';

import { INewsRes } from 'types/frameWidgetRes';

import { colors, flexbox, textEllipsis } from 'styles/common';
import errorImage from 'assets/images/error-image.webp';

interface IPage {
  cursor?: number;
  list?: INewsRes[];
}
export const NewsTablePreview = ({
  template,
  pages,
  fetchNextPage,
}: {
  template: string;
  pages: INewsRes[];
  fetchNextPage?: (options?: FetchNextPageOptions) => Promise<UseInfiniteQueryResult>;
}) => {
  return (
    <>
      <Ul>
        {pages.map((item, index) => {
          const key = `widget-${index}`;
          return (
            <Li key={key} $template={template}>
              <ImageWrapper>
                <img
                  src={item.thumbnail_url || errorImage}
                  alt={item.site_name}
                  width={80}
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null;
                    currentTarget.src = errorImage;
                  }}
                />
              </ImageWrapper>

              <div>
                <TitleP>{item.news_title}</TitleP>
                <ContentP>{item.news_text}</ContentP>

                <FlexDiv>
                  <MinuteP>{item.time_str}</MinuteP>
                  <AButton to={item.url} target='_blank'>
                    본문보기
                  </AButton>
                </FlexDiv>
              </div>
            </Li>
          );
        })}
      </Ul>
      <LoadMore type='button' onClick={() => fetchNextPage && fetchNextPage()}>
        더보기
      </LoadMore>
    </>
  );
};

const NewsTable = ({
  template,
  pages,
  fetchNextPage,
}: {
  template: string;
  pages: IPage[];
  fetchNextPage?: (options?: FetchNextPageOptions) => Promise<UseInfiniteQueryResult>;
}) => {
  return (
    <>
      <Ul>
        {pages &&
          pages.map((i) => {
            return i.list.map((item, index) => {
              const key = `widget-${index}`;
              return (
                <Li key={key} $template={template}>
                  <ImageWrapper>
                    <img
                      src={item.thumbnail_url || errorImage}
                      alt={item.site_name}
                      width={80}
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null;
                        currentTarget.src = errorImage;
                      }}
                    />
                  </ImageWrapper>

                  <div>
                    <TitleP>{item.news_title}</TitleP>
                    <ContentP>{item.news_text}</ContentP>

                    <FlexDiv>
                      <MinuteP>{item.time_str}</MinuteP>
                      <AButton to={item.url} target='_blank'>
                        본문보기
                      </AButton>
                    </FlexDiv>
                  </div>
                </Li>
              );
            });
          })}
      </Ul>
      <LoadMore type='button' onClick={() => fetchNextPage()}>
        더보기
      </LoadMore>
    </>
  );
};
//
const FlexDiv = styled.div`
  ${flexbox({ fd: 'row', jc: 'space-between' })}
  margin-top: 8px;
`;

const ImageWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  max-height: 120px;
  overflow: hidden;
  border-radius: 4px;

  img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: auto;
    border-radius: 4px;
  }
`;

const TitleP = styled.p`
  ${textEllipsis({ n: 1 })};
  font-size: 16px;
`;

const ContentP = styled.p`
  ${textEllipsis({ n: 3 })};
  font-size: 14px;
  color: ${colors.GRAY7};
  padding-right: 20px;
`;

const MinuteP = styled.p`
  color: ${colors.GRAY7};
  font-size: 12px;
`;

const AButton = styled(Link)`
  display: inline-block;
  color: ${colors.INDIGO};
  font-size: 12px;
  border: 1px solid ${colors.INDIGO};
  padding: 8px 12px;
  border-radius: 20px;
`;

const LoadMore = styled.button`
  color: ${colors.WHITE};
  font-size: 14px;
  background: ${colors.INDIGO};
  padding: 10px 20px;
  border-radius: 20px;
  margin: 20px auto 0;
  display: block;
`;

const Ul = styled.ul`
  width: 100%;
  padding: 10px 0;

  .gray78 {
    color: ${colors.GRAY78};
    text-align: left;
  }
`;

const Li = styled.li<{ $template: string }>`
  position: relative;
  display: grid;
  grid-template-columns: 1fr 4fr;
  gap: 20px;
  align-items: center;

  text-align: left;
  height: 165px;

  padding: ${({ $template }) => {
    if ($template === 'template1') return '20px 0';
    return '20px';
  }};

  & + li {
    margin-top: 8px;
  }

  ${({ $template }) => {
    if ($template === 'template1') return `& + li { border-top: 1px solid ${colors.GRAYE}; }`;
    if ($template === 'template2') return `border: 1px solid ${colors.GRAYE}; border-radius: 12px;box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04)`;
    return `undefined`;
  }};
`;

export default NewsTable;
