import Modal from 'react-modal';
import { MouseEventHandler, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

import StyledInput from 'components/StyledInput';
import { TEmbed, TEmbedTemplate, TFormType, TWidget, TWidgetTemplate } from 'types/enum';

import { colors, flexbox, modalStyle } from 'styles/common';
import { BigButton, ModalDiv } from 'styles/StyledComponent';
import { CloseIcon } from 'assets/svgs';

const CommonViewSource = ({
  pageType,
  currentItem,
  isOpen,
  handleModalClose,
}: {
  pageType: TFormType;
  currentItem: { uuid: string; template: TWidgetTemplate | TEmbedTemplate; datatype: TWidget | TEmbed };
  isOpen: boolean;
  handleModalClose: MouseEventHandler;
}) => {
  const [page, setPage] = useState<number>();
  const [width, setWidth] = useState<number>();
  const [height, setHeight] = useState<number>();
  const [source, setSource] = useState<string>();
  const [enableNext, setEnableNextNext] = useState<boolean>(false);
  const [enableCopy, setEnableCopy] = useState<boolean>(false);

  const { uuid, template, datatype } = currentItem || {};

  const minWidth = useMemo(() => {
    if (template === 'side') return 200;
    if (template === 'center') return page * 185;
    if (template === 'top') return 150;

    if (datatype === 'lawnum' || datatype === 'bill') return 500;
    if (datatype === 'asmman') return 500;
    return 0;
  }, [datatype, template, page]);

  const minHeight = useMemo(() => {
    if (template === 'side' && datatype === 'news') return 92 + page * 200;
    if (template === 'side' && datatype === 'business') return 92 + page * 130;
    if (template === 'side' && datatype === 'service') return 92 + page * 120;
    if (template === 'side' && datatype === 'bill') return 92 + page * 150;

    if (template === 'center' && datatype === 'news') return 240;
    if (template === 'center' && datatype !== 'news') return 150;
    if (template === 'top') return 20;

    if (template === 'template1' && (datatype === 'lawnum' || datatype === 'bill')) return 333 + (page - 1) * 135;
    if (template === 'template2' && (datatype === 'lawnum' || datatype === 'bill')) return 410 + (page - 1) * 180;
    if (datatype === 'asmman') return 300;
    return 0;
  }, [template, page, datatype]);

  useEffect(() => {
    if (!isNaN(width) && !isNaN(height) && !isNaN(page))
      setSource(
        `<iframe src="${window.location.origin}/${pageType}/${uuid}?cpp=${page}" frameborder="0" width="${width}" height="${height}"></iframe>`
      );
  }, [pageType, uuid, width, height, page, currentItem]);

  useEffect(() => {
    if (page > 0 && page < 31) {
      setEnableNextNext(true);
    } else {
      setEnableNextNext(false);
    }
  }, [page]);

  useEffect(() => {
    if (width >= minWidth && height >= minHeight) setEnableCopy(true);
    else setEnableCopy(false);
  }, [width, height, minHeight, minWidth]);

  useEffect(() => {
    setWidth(undefined);
    setHeight(undefined);
    setSource('');
    setEnableCopy(false);
    setEnableNextNext(false);
  }, [isOpen]);

  const handleCopy = (text: string) => {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(text).then(() => {
        // eslint-disable-next-line no-alert
        alert('소스가 클립보드에 복사되었습니다.');
      });
    } else {
      const textarea = document.createElement('textarea');
      textarea.value = text;
      textarea.style.top = '0';
      textarea.style.left = '0';
      textarea.style.position = 'fixed';

      document.body.appendChild(textarea);
      textarea.focus();
      textarea.select();
      document.execCommand('copy');
      document.body.removeChild(textarea);
      // eslint-disable-next-line no-alert
      alert('소스가 클립보드에 복사되었습니다.');
    }
  };

  if (!template) return null;

  return (
    <Modal isOpen={isOpen} ariaHideApp={false} shouldCloseOnOverlayClick={false} style={modalStyle}>
      <ModalWrapper>
        <div className='title'>
          <p>소스보기</p>
          <button type='button' onClick={handleModalClose}>
            <CloseIcon />
          </button>
        </div>

        <textarea
          value={source}
          onChange={(e) => setSource(e.currentTarget.value)}
          readOnly
          style={{ cursor: enableNext ? 'text' : 'not-allowed' }}
        />

        <Wrapper>
          <Label $enable>템플릿</Label>
          <StyledInput defaultValue={currentItem.template} disabled />
        </Wrapper>

        <Wrapper>
          <Label $enable>페이지당 항목 (최대 30)</Label>
          <StyledInput type='number' id='pagenum' required onChange={(e) => setPage(e.currentTarget.valueAsNumber)} />
        </Wrapper>

        <Wrapper>
          <Label $enable={enableNext}>가로 (최소 {!isNaN(minWidth) && minWidth}px)</Label>
          <StyledInput
            type='number'
            id='width'
            unit='px'
            min={!isNaN(minWidth) ? minWidth : 0}
            onChange={(e) => setWidth(e.currentTarget.valueAsNumber)}
            disabled={!enableNext}
          />
        </Wrapper>
        <Wrapper>
          <Label $enable={enableNext}>세로 (최소 {!isNaN(minHeight) && minHeight}px)</Label>
          <StyledInput
            type='number'
            id='height'
            unit='px'
            min={!isNaN(minHeight) ? minHeight : 0}
            onChange={(e) => setHeight(e.currentTarget.valueAsNumber)}
            disabled={!enableNext}
          />
        </Wrapper>

        <BigButton type='button' className='copy' onClick={() => handleCopy(source)} disabled={!enableCopy}>
          복사
        </BigButton>
      </ModalWrapper>
    </Modal>
  );
};

const Wrapper = styled.div`
  ${flexbox({ jc: 'space-between', gap: 16 })};
`;

const Label = styled.p<{ $enable: boolean }>`
  color: ${({ $enable }) => {
    if ($enable) return `${colors.PRIMARY}`;
    return `${colors.GRAYC}`;
  }};
  display: inline-block;
  min-width: 125px;
  font-size: 13px;
`;

const ModalWrapper = styled(ModalDiv)`
  textarea {
    display: block;
    width: 479px;
    height: 120px;
    margin: 30px 0;
    padding: 12px 16px;
    border: 1px solid ${colors.GRAYD};
    border-radius: 7px;
    line-height: 1.2;
    background: ${colors.GRAYF2};
  }

  > span {
    color: ${colors.PRIMARY};
    font-size: 13px;
  }

  & > div {
    margin-bottom: 6px;

    &:last-of-type {
      margin-bottom: 30px;
    }
  }

  .copy {
    width: 100%;
  }
`;

export default CommonViewSource;
