// https://github.com/axios/axios

import axios, { AxiosError, AxiosResponse, AxiosRequestConfig, ResponseType } from 'axios';

const baseSettings = {
  timeout: 60 * 1000,
  baseURL: process.env.REACT_APP_API_BASE_URL,
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const requestError = (error: any): any => {
  return Promise.reject(error);
};

const resolveResponse = (response: AxiosResponse): AxiosResponse => response;

const responseError = (error: AxiosError): Promise<never> => {
  // eslint-disable-next-line no-console
  console.log(error);
  return Promise.reject(error);
};

const plainInstance = axios.create(baseSettings);

const instance = axios.create({
  ...baseSettings,
  withCredentials: false,
});
instance.defaults.headers.common.Authorization = `Bearer ${window.sessionStorage.getItem('tk')}`;
instance.interceptors.request.use((config) => config, requestError);
instance.interceptors.response.use(resolveResponse, responseError);

const isAxiosError = <E>(err: unknown | AxiosError<E>): err is AxiosError => {
  return axios.isAxiosError(err);
};

export { axios, instance, plainInstance, isAxiosError };
export type { AxiosRequestConfig, ResponseType };
