import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { TPageType } from 'types/enum';
import { useEmbedPreviewGet } from 'hooks/useEmbed';

import BlockPage from './BlockPage';
import AssmanTable from 'pages/IFrameEmbed/AsmmanTable';
import BillTable from 'pages/IFrameEmbed/BillTable';
import { NewsTablePreview } from 'pages/IFrameEmbed/NewsTable';

const EmbedDataFetch = ({
  title,
  type,
  template,
  queryString,
  view,
}: {
  title: string;
  type: TPageType<'embed'>;
  template: string;
  queryString: string;
  view: number;
}) => {
  const [searchParams] = useSearchParams();
  const pageNum = Number(searchParams.get('p')) || 1;
  const [srchText, setSrchText] = useState<string>();

  const { data, isError } = useEmbedPreviewGet({ qryType: type, qryString: queryString, pageParam: pageNum });

  if (!data || isError) return <BlockPage />;

  const { list, totcnt } = data || {};

  return (
    <>
      {type === 'news' && <NewsTablePreview pages={list} template={template} />}
      {type === 'asmman' && <AssmanTable title={title} list={list} template={template} />}
      {(type === 'lawnum' || type === 'bill') && (
        <BillTable
          title={title}
          type={type}
          view={view}
          list={list}
          template={template}
          totcnt={totcnt}
          srchText={srchText}
          setSrchText={setSrchText}
        />
      )}
    </>
  );
};

export default EmbedDataFetch;
