import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

import { colors, flexbox } from './common';

export const RegisterUl = styled.ul`
  position: relative;
  border-bottom: 1px solid ${colors.GRAYD};

  p.label {
    margin: 50px 0 8px;
  }

  > li {
    ${flexbox({ jc: 'flex-start', gap: 20 })};
    border-top: 1px solid ${colors.GRAYD};
    padding: 20px 0;
  }
`;
export const FlexDiv = styled.div<{ $fd?: string; $wrap?: string; $gap?: number }>`
  ${({ $fd = 'row', $gap = 20 }) => {
    return `${flexbox({ jc: 'center', ai: 'flex-start', gap: $gap, fd: $fd })};`;
  }};
  ${({ $wrap }) => {
    if ($wrap) return `flex-wrap: ${$wrap}`;
    return `flex-wrap: nowrap`;
  }};
`;
export const RegisterWrapper = styled.div`
  .last {
    button {
      width: auto;
    }

    button + button {
      margin-left: 10px;
    }

    button + a {
      margin-left: 10px;
    }
  }

  form {
    background: #fafbfc;
    border-radius: 8px;
    border: 1px solid ${colors.GRAYA};
    padding: 40px;
    margin-bottom: 50px;
  }

  input[type='text'],
  input[type='number'] {
    min-width: 320px;
  }

  .row {
    ${flexbox({ jc: 'flex-start', gap: 20 })};
    flex-wrap: wrap;
    position: relative;
  }

  .row + .row {
    margin-top: 50px;
  }

  .label {
    margin: 0;
  }

  .title {
    min-width: 90px;
  }

  .cancel {
    text-align: left;

    svg {
      position: relative;
      top: 3px;
      margin-left: 3px;
      height: 15px;
      fill: ${colors.GRAY9};
    }

    &:hover {
      color: ${colors.RED};

      svg {
        fill: ${colors.RED};
      }
    }
  }
`;

export const ModalDiv = styled.div`
  padding: 12px;

  .title {
    ${flexbox({ jc: 'space-between', ai: 'flex-start' })};
    font-size: 20px;
    color: ${colors.PRIMARY};
    font-weight: 700;

    svg {
      fill: ${colors.GRAY9};
    }
  }
`;

export const BigButton = styled.button`
  display: inline-block;
  border-radius: 4px;
  background: ${colors.PRIMARY};
  color: ${colors.WHITE};
  font-size: 16px;
  cursor: pointer;
  line-height: 45px;
  padding: 0 35px;

  &:disabled {
    background: ${colors.GRAY9};
    cursor: not-allowed;
  }
`;

export const BigButtonRed = styled(BigButton)`
  background: ${colors.WHITE};
  color: ${colors.RED};
  line-height: 41px;
  padding: 0 31px;
  border: 2px solid ${colors.RED};
`;

export const BigButtonLine = styled(BigButton)`
  background: ${colors.WHITE};
  color: ${colors.PRIMARY};
  line-height: 41px;
  padding: 0 31px;
  border: 2px solid ${colors.PRIMARY};
`;

export const BigNav = styled(NavLink)`
  display: inline-block;
  border-radius: 4px;
  background: ${colors.PRIMARY};
  color: ${colors.WHITE};
  font-size: 16px;
  cursor: pointer;
  line-height: 45px;
  padding: 0 35px;

  &:disabled {
    background: ${colors.GRAY9};
    cursor: not-allowed;
  }
`;

export const BigNavRed = styled(BigNav)`
  background: ${colors.WHITE};
  color: ${colors.RED};
  line-height: 41px;
  padding: 0 31px;
  border: 2px solid ${colors.RED};
`;

export const H1 = styled.h1`
  font-size: 40px;
  color: ${colors.PRIMARY};
  line-height: 150px;
  text-align: center;
  font-weight: 700;
`;
export const Container = styled.div`
  .info-wrapper {
    ${flexbox({ jc: 'space-between' })};
    margin-bottom: 30px;

    p {
      font-weight: 700;
    }
  }

  .list-wrapper {
    min-height: 500px;
  }
`;
