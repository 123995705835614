import { useMutation, UseMutationResult, useQuery, useQueryClient } from 'react-query';
import { AxiosError } from 'axios';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { RootState, useAppDispatch, userActions } from 'store';
import { plainInstance, instance } from 'hooks/useAxios';

export interface IUserInfo {
  id: string;
  name: string;
  managerName: string;
  managerEmail: string;
  embedPermission: boolean;
  widgetPermission: boolean;
  logo: string;
}

export interface ILoginPost {
  account: string;
  password: string;
}

export interface ISignInRes {
  success: boolean;
  accessToken?: string;
  code?: string;
  msg?: string;
}

export interface IUserInfoRes {
  id: string;
  name: string;
  managerName: string;
  managerEmail: string;
  embedPermission: number;
  widgetPermission: number;
  logo: string;
}

export interface IUserMeRes {
  success: boolean;
  refresh?: boolean;
  userInfo?: IUserInfoRes;
  code?: string;
  msg?: string;
}

const loginPost = ({ account, password }: ILoginPost): Promise<ISignInRes> => {
  return plainInstance
    .post('/users/signIn', {
      account,
      password,
    })
    .then((res) => res.data);
};

export const useUserLoginPost = (): UseMutationResult<ISignInRes, AxiosError> => {
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();

  return useMutation(loginPost, {
    onSuccess: async ({ success, accessToken, msg }: ISignInRes) => {
      if (success) {
        dispatch(userActions.login({ token: accessToken }));
        await queryClient.refetchQueries(['userMe']);
      } else
        toast.error(`로그인 실패 (${msg})`, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
          theme: 'light',
        });
    },
    useErrorBoundary: true,
  });
};

export const userGet = (): Promise<IUserMeRes> => {
  return instance.get(`/users/me`).then((res) => res.data);
};

export const useUserGet = () => {
  const dispatch = useAppDispatch();
  const { token, user } = useSelector((state: RootState) => state.user);

  return useQuery<IUserMeRes, AxiosError>(['userMe'], userGet, {
    onSuccess: ({ success, userInfo, msg }: IUserMeRes) => {
      if (success) {
        dispatch(userActions.setUser(userInfo));
      } else {
        dispatch(userActions.logout());
        toast.error(`${msg}`, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
          theme: 'light',
        });
      }
    },
    suspense: true,
    useErrorBoundary: true,
    enabled: !!token && !!user,
  });
};
