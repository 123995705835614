import { cloneElement, ReactElement, ReactNode, MouseEvent } from 'react';
import styled from 'styled-components';

import { colors, commonLabelStyle, flexbox } from 'styles/common';

export const Radio = ({
  name,
  value,
  isDefaultValue,
  children,
  readOnly,
}: {
  name?: string;
  value: string;
  isDefaultValue?: boolean;
  children?: ReactNode;
  readOnly?: boolean;
}) => {
  const handleDisabed = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };
  return readOnly ? (
    <>
      <input type='radio' name={name} id={`${name}-${value}`} onClick={handleDisabed} value={value} defaultChecked={isDefaultValue} required />
      <label htmlFor={`${name}-${value}`}>{children || value}</label>
    </>
  ) : (
    <>
      <input type='radio' name={name} id={`${name}-${value}`} value={value} defaultChecked={isDefaultValue} disabled={readOnly} required />

      <label htmlFor={`${name}-${value}`}>{children || value}</label>
    </>
  );
};

export const StyledRadio = ({
  label,
  name,
  children,
  theme,
  defaultValue,
  readOnly,
}: {
  label?: string;
  name: string;
  children: ReactElement[];
  theme?: string;
  defaultValue?: string;
  readOnly?: boolean;
}) => {
  return (
    <div>
      {label && <Label>{label}</Label>}
      <Wrapper $tabnum={children.length} $theme={theme}>
        {children.map((item, index) => {
          const key = `tab-${index}`;
          const isRequired = index === 0;
          const isDefaultValue = item.props.value === defaultValue || index === 0;
          // const isDefaultValue = index === 0;
          const babyWithProps = cloneElement(item, { name, isRequired, isDefaultValue, readOnly });
          return (
            <RadioButton key={key} readOnly={readOnly}>
              {babyWithProps}
            </RadioButton>
          );
        })}
      </Wrapper>
    </div>
  );
};
const Label = styled.p`
  ${commonLabelStyle};
`;

const RadioButton = styled.li<{ readOnly?: boolean }>`
  input {
    position: absolute;
    right: -9999px;
    width: 0;
    height: 0;
    opacity: 0;

    & + label {
      font-size: 14px;
      display: block;
      line-height: 40px;
      color: ${colors.GRAYA};
      background: ${colors.WHITE};
      text-align: center;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      word-break: break-all;
      padding: 0 8px;
      border: 1px solid ${colors.GRAYA};
      border-radius: 10px;
      border-color: ${colors.GRAYA};
      color: ${colors.GRAYA};
      cursor: ${({ readOnly }) => {
        if (readOnly) return `not-allowed`;
        return `pointer`;
      }};
    }

    &:checked + label {
      background: ${colors.PRIMARY};
      color: ${colors.WHITE};
      border-color: ${colors.PRIMARY};
    }
  }
`;

const Wrapper = styled.ul<{ $tabnum: number; $theme?: string }>`
  ${flexbox({ fd: 'row' })};

  width: 100%;
  gap: 10px;

  li {
    width: ${({ $tabnum }) => {
      return `calc(100% / ${$tabnum})`;
    }};
  }
`;
