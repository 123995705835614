import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import errorImage from 'assets/images/errorpage-image.webp';
import { colors, flexbox } from 'styles/common';
import { useEffect } from 'react';

const WrongPage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setTimeout(() => {
      navigate('/');
    }, 2000);

    return () => {
      clearTimeout(timer);
    };
  }, [navigate]);

  return (
    <Wrapper>
      <InnerWrapper>
        <img src={errorImage} alt='error' width={240} height={181} />
        <h1>문제가 발생했습니다.</h1>
      </InnerWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  ${flexbox({ ai: 'center', jc: 'center' })}
  width: 100vw;
  height: 100vh;
`;

const InnerWrapper = styled.div`
  ${flexbox({ ai: 'center', gap: 20 })}
  padding: 30px;
  flex-wrap: wrap;
  background-color: ${colors.WHITE};
  text-align: center;

  h1 {
    font-size: clamp(1.5rem, 2.5vw, 4rem);
    font-weight: 500;
    line-height: 1.618;
    color: ${colors.GRAY4};
  }
`;

export default WrongPage;
